<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card mt-2">
        <h2 class="text-center mt-2">Vendor Premium Dynamic AX</h2>
        <div class="card-body">
          <b-row v-if="isError" class="justify-content-center mt-2">
            <b-col lg="10">
              <b-alert variant="danger" show dismissible>
                Opps .. Something is wrong
              </b-alert>
            </b-col>
          </b-row>
          <div class="row justify-content-md-start">
            <div class="col-sm-5">
              <input
                type="text"
                v-model="searchForm"
                class="form-control"
                placeholder="type product name..."
                @input="debounceSearch"
              />
              <span v-if="typing"> <em> searching... </em> </span>
            </div>
            <div class="col-sm-1">
              <router-link
                class="btn btn-block btn-primary"
                :to="{ path: 'upload-vendor-premium' }"
              >
                <i class="fa fa-upload"></i>
              </router-link>
            </div>
          </div>
          <div class="row justify-content-end">
            <label for="" class="col-sm-auto font-weight-bold">Total :</label>
            <strong class="col-sm-auto">{{ formatNumber(totalRows) }}</strong>
          </div>
          <b-table
            sticky-header="500px"
            :isLoading="isLoading"
            responsive="xl"
            show-empty
            striped
            :busy="isLoading"
            :fields="fields"
            hover
            :items="items"
          >
            <template #cell(No)="row">
              {{ (currentPage - 1) * perPage + (row.index + 1) }}.
            </template>

            <template #cell(vendor_account)="row">
              <div style="width: 100px">
                {{ row.item?.vendor_account ? row.item?.vendor_account : '-' }}
              </div>
            </template>

            <template #cell(vendor_name)="row">
              <div style="width: 200px">
                {{ row.item?.vendor_name ? row.item?.vendor_name : '-' }}
              </div>
            </template>

            <template #cell(name)="row">
              <div style="width: 200px">
                {{ row.item?.name ? row.item?.name : '-' }}
              </div>
            </template>

            <template #cell(premium_dynamicax)="row">
              <div style="width: 200px">
                {{ row.item?.name ? row.item?.name : '-' }}
              </div>
            </template>

            <template #cell(created)="row">
              <div style="width: 250px">
                {{
                  row.item.created ? formatDateString(row.item.created) : '-'
                }}
              </div>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-dark my-2">
                <b-spinner
                  class="align-middle"
                  variant="dark"
                  type="grow"
                  label="Loading .."
                >
                </b-spinner>
                Loading ..
              </div>
            </template>
          </b-table>
        </div>
        <b-row class="justify-content-start ml-1">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
export default {
  name: 'ListPremiumVendor',
  data() {
    return {
      currentPage: 1,
      perPage: 50,
      fields: [
        { key: 'No', label: 'No' },
        { key: 'name', label: 'Product Name' },
        { key: 'vendor_name', label: 'Vendor Name' },
        { key: 'vendor_account', label: 'Vendor Account' },
        { key: 'premium_dynamicax', label: 'Premium Dynamix AX' },
        { key: 'created', label: 'created' },
      ],
      typing: '',
      searchForm: null,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.vendorPremium.isLoading,
      isError: (state) => state.vendorPremium.isError,
      totalRows: (state) => state.vendorPremium.totalRows,
      items: (state) => state.vendorPremium.items,
    }),
  },
  watch: {
    currentPage() {
      this.fetchData();
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Vendor Premium AX | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions('vendorPremium', ['fetchVendorPremium']),

    fetchData() {
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
      };
      if (this.searchForm) payload.q = this.searchForm;
      this.fetchVendorPremium(payload);
    },

    formatDateString(date) {
      return new Date(date).toString();
    },

    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },

    formatNumber(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }
      return '0';
    },

    debounceSearch(event) {
      this.typing = 'You are typing';
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.typing = null;
        this.message = event.target.value;
        this.fetchData();
      }, 600);
    },
  },
};
</script>
<style scoped>
input::placeholder {
  font-style: italic;
}
</style>
